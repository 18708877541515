<script>
import { validationMixin } from '@/mixins/form/validation';
import Layout from '@/router/layouts/auth';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { authMethods } from '@/state/helpers';
import brandMixin from "@/mixins/brand.mixin";

export default {
  mixins: [validationMixin, brandMixin],
  components: {
    Layout,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      form: {
        password: '',
        password_confirmation: '',
      },
      resetErrorText: null,
    };
  },
  methods: {
    ...authMethods,
    tryToReset(values, actions) {
      this.loading = true;

      this.resetErrorText = null;

      this.resetPassword({
        ...this.form,
        token: this.$route.query.token,
        email: this.$route.query.email,
      })
        .then(() => {
          this.$router.push(
            this.$route.query.redirectFrom || {
              name: 'home',
            }
          );
        })
        .catch(({ response }) => {
          this.resetErrorText = this.handleResponseErrors(response, { values, actions });

          if (!this.resetErrorText) {
            this.resetErrorText = response?.data?.error?.message;
          }

          this.loading = false;
        });
    },
  },
  metaInfo() {
    return {
      title: this.$t('Auth.Reset.PageTitle'),
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-12">
                  <div class="text-dark p-4 text-center">
                    <h5>{{ $t('Auth.Reset.PageTitle') }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <div class="avatar-md profile-user-wid mb-4 mx-auto">
                  <span class="avatar-title rounded-circle bg-light">
                    <img :src="$getConfigValue('logoPath', 'VUE_APP_LOGO_URL')" alt height="42" />
                  </span>
                </div>
              </div>

              <b-alert :show="!!resetErrorText" class="mt-3" variant="danger" dismissible>
                {{ resetErrorText }}
              </b-alert>

              <Form class="p-2" @submit="tryToReset">
                <Field name="password" rules="required|min:8" :label="$t('Form.Password')" v-slot="{ errors, field }">
                  <b-form-group class="mb-3" :label="$t('Form.Password')" label-for="password">
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      type="password"
                      :placeholder="$t('Form.PasswordPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="password" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field
                  name="password_confirmation"
                  rules="required|confirmed:@password"
                  :label="$t('Form.Password')"
                  v-slot="{ errors, field }"
                >
                  <b-form-group class="mb-3" :label="$t('Form.PasswordConfirmation')" label-for="password_confirmation">
                    <b-form-input
                      id="password_confirmation"
                      v-model="form.password_confirmation"
                      type="password"
                      :placeholder="$t('Form.PasswordConfirmationPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="password_confirmation" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <div class="mt-4 d-grid">
                  <b-button type="submit" variant="dark" class="btn-block">{{ $t('Auth.Forgot.Reset') }}</b-button>
                </div>
              </Form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="mt-5 text-center">
            <p>
              {{ $t('Auth.Register.HaveAccount') }}
              <router-link to="/login" class="fw-medium text-primary">{{ $t('Auth.Register.Login') }}</router-link>
            </p>
            <p>© {{ new Date().getFullYear() }}</p>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
